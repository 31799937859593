export default {
  data() {
    return {
      validate: {
        sendMail: {
          accountStartDate: { isValid: true, errMsg: "" },
        },
      },

      isValidForm: {
        sendMail: true,
      },
    };
  },
  methods: {
    sendMailValidateCheck() {
      const v = this.validate.sendMail;
      let isValidForm = true;

      if (!v.accountStartDate.isValid) {
        v.accountStartDate.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }

      return isValidForm;
    },

    runValidate() {
      let isValidForm = false;
      this.isValidForm.sendMail = this.sendMailValidateCheck();
      for (let k in this.isValidForm) {
        if (this.isValidForm[k]) {
          isValidForm = true;
        } else {
          isValidForm = false;
          break;
        }
      }

      return isValidForm;
    },
  },
};
