
export default {
  data() {
    return {
      disposer: {
        isCbaContract: false,
        contractStatusInfo: {
          id: 0,
          name: ""
        },
        accountStartDate: "",
        disposerBaseInfo: {
          businessCode: null,
          legalPersonalityType: {
            id: 0,
            name: ""
          },
          id: 0,
          name: "",
          zipCode: "",
          prefectures: {
            id: 0,
            name: ""
          },
          address: "",
          buildingName: "",
          tel: "",
          fax: "",
          isActive: false
        },
        staffName: "",
        staffMail: "",
        cbaItem: [
          {
            id: 0,
            name: "",
          }
        ],
        remarks: "",
        licenceMunicipalityList: [
          {
            licenceLead3No: "",
            municipalityName: "",
            disposalLicenceList: [
              {
                disposalLicenceId: 0,
                licenceNo: "",
                licenceExpiredDate: "",
                isExcellent: false,
                licenceFileName: "",
                targetJwnetItemList: [
                  {
                    id: 0,
                    name: ""
                  }
                ],
                disposalWayList: [
                  {
                    disposalLicenceDisposalWayId: 0,
                    disposalWayType: {
                      id: 0,
                      name: "",
                    },
                    disposalDetailWay: "",
                  }
                ],
                isActive: false,
                isUpdate: false
              }
            ]
          }
        ],
        licenceCityList: [
          {
            cityName: "",
            disposalLicenceList: [
              {
                disposalLicenceId: 0,
                licenceNo: "",
                licenceExpiredDate: "",
                isExcellent: false,
                licenceFileName: null,
                targetJwnetItemList: [
                  {
                    id: 0,
                    name: ""
                  }
                ],
                disposalWayList: [
                  {
                    disposalLicenceDisposalWayId: 0,
                    disposalWayType: {
                      id: 0,
                      name: "",
                    },
                    disposalDetailWay: "",
                  }
                ],
                isActive: false,
                isUpdate: false
              }
            ]
          }
        ],
        disposalSiteList: [
          {
            disposalSiteBaseInfo:
              {
                id: 0,
                name: "",
                zipCode: "",
                prefecturesId: 0,
                prefecturesName: "",
                address: "",
                buildingName: "",
                tel: "",
                fax: "",
                isActive: false,
              },
            jwnetContractNo: "",
            ediKey: "",
            disposalStoreCode: "",
            publicConfirmationNo: "",
            disposalSiteLicenceList: [
              {
                id: 0,
                disposalLicenceNo: ""
              }
            ],
            finalDisposalSiteList: [],
          },
        ],
        isDisposalProxy: false,
        accountInfo: {
          memberId: 0,
          name: "",
          mail: "",
          tel: "",
          mobilePhone: ""
        }
      },
    };
  },
};
