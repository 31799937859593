<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="処分事業者詳細" />
    <AppSideMenu />
    <main id="main" class="l-main">
      <ToastMessage :toastMsg.sync="toastMsg" />
      <div class="l-container">
        <div class="c-alart">
          <div class="icon"></div>
          <p>契約データの一括登録中に、関連事業者の情報を編集しないでください。実施前後には必ず社内への周知を行ってください。</p>
        </div>
      </div>
      <div class="l-container" v-if="Object.keys(form).length">
        <!-- 会社情報 ================================================== -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap l-flex">
            <h3 class="c-lead icon">会社情報</h3>
            <div class="c-collectorText">

              <div class="c-collectorContent">準備完了メール送信日</div>
              <InputDate
                  v-show="!form.accountStartDate"
                  :value.sync="startDate"
                  :validation="validateBlank"
                  :isValid.sync="validate.sendMail.accountStartDate.isValid"
                  :errMsg.sync="validate.sendMail.accountStartDate.errMsg"
                  class="c-collectorContent"
              />
              <div v-show="form.accountStartDate" class="c-collectorContent">
                {{ disposer.accountStartDate | dateJpYMDFormat }}
              </div>
              <div v-if="!form.accountStartDate"
                   :class="{ disabled: !disposer.isCbaContract || disposer.contractStatusInfo.id !== 10 || isProcessing }"
                   @click="formHandler()"
                   class="c-btn secondary sm c-collectorContent"
              >
                準備完了メール送付
              </div>
            </div>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>会社名</label>
              </div>
              <div class="c-inputWrap__items">
                {{ companyFullName }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>住所</label>
              </div>
              <div class="c-inputWrap__items">
                〒{{ disposer.disposerBaseInfo.zipCode }}<br />
                {{ disposer.disposerBaseInfo.prefectures.name }}
                {{ disposer.disposerBaseInfo.address }}
                {{ disposer.disposerBaseInfo.buildingName }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>電話番号</label>
              </div>
              <div class="c-inputWrap__items">
                {{ disposer.disposerBaseInfo.tel | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>FAX番号</label>
              </div>
              <div class="c-inputWrap__items">
                {{ disposer.disposerBaseInfo.fax | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>担当者名</label>
              </div>
              <div class="c-inputWrap__items">
                {{ disposer.staffName | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>担当者メールアドレス</label>
              </div>
              <div class="c-inputWrap__items word-break">
                {{ disposer.staffMail | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>事業者コード</label>
              </div>
              <div class="c-inputWrap__items word-break">
                <dd>{{ disposer.disposerBaseInfo.businessCode | blankText }}</dd>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>得意とするCBA品目</label>
              </div>
              <div class="c-inputWrap__items">
                <ul
                  class="l-flex c-inputWrap__items__cols"
                  v-if="disposer.cbaItem.length"
                >
                  <li v-for="item in disposer.cbaItem" :key="'cbaItem-' + item.id">
                    {{ item.name }}
                  </li>
                </ul>
                <div v-else>-</div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>備考</label>
              </div>
              <div class="c-inputWrap__items">
                {{ disposer.remarks | blankText }}
              </div>
            </div>
          </div>
        </div>
        <!-- /.l-contents -->

        <!-- 産業廃棄物許可証情報 ================================================== -->
        <div class="c-lead l-area" v-if="disposer.licenceMunicipalityList.length">許可証情報</div>
        <div
          v-for="(list, municipalityIndex) in disposer.licenceMunicipalityList"
          :key="'m-' + municipalityIndex"
        >
          <div class="c-lead l-item">{{ list.municipalityName }}</div>
          <div
            class="l-contents l-box"
            v-for="(licence, licenceIndex) in list.disposalLicenceList"
            :key="'m-' + municipalityIndex + '-licence-' + licenceIndex"
          >
            <div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>ステータス</label>
                </div>
                <div class="c-inputWrap__items">
                  <div v-if="!licence.isActive">無効</div>
                  <div v-else-if="licence.isUpdate">更新中</div>
                  <div v-else>有効</div>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>許可証番号</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ licence.licenceNo }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>許可証期限</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ licence.licenceExpiredDate }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>優良認定</label>
                </div>
                <div class="c-inputWrap__items">
                  <div v-if="licence.isExcellent" class="c-excellent"/>
                  <div v-else>-</div>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>許可証PDF</label>
                </div>
                <div class="c-inputWrap__items">
                  <a v-if="licence.licenceFileName" class="c-text text-link c-entrustText__text"
                     @click="downloadLicenceFile(licence.disposalLicenceId, licence.licenceFileName, false)">
                    {{ licence.licenceFileName }}
                  </a>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>対象廃棄物種類</label>
                </div>
                <div class="c-inputWrap__items">
                  <ul
                    class="l-flex c-inputWrap__items__cols"
                    v-if="licence.targetJwnetItemList.length"
                  >
                    <li>
                      {{ licence.targetJwnetItemList.map(jwnetItem => jwnetItem.name).join("、") }}
                    </li>
                  </ul>
                  <div v-else>-</div>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>処分方法</label>
                </div>
                <div class="c-inputWrap__items">
                  <div
                    class="l-item"
                    v-for="(
                      disposalWay, disposalWayIndex
                    ) in licence.disposalWayList"
                    :key="'disposalWay-' + disposalWayIndex"
                  >
                    <p>{{ disposalWay.disposalWayType.name }}</p>
                    <p>{{ disposalWay.disposalDetailWay }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 一般廃棄物許可証情報 ================================================== -->
        <div
          v-for="(list, licenceCityIndex) in disposer.licenceCityList"
          :key="'l-' + licenceCityIndex"
        >
          <div class="c-lead l-item">{{ list.cityName }}</div>
          <div
            class="l-contents l-box"
            v-for="(licence, licenceIndex) in list.disposalLicenceList"
            :key="'l-' + licenceCityIndex + '-licence-' + licenceIndex"
          >
            <div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>ステータス</label>
                </div>
                <div class="c-inputWrap__items">
                  <div v-if="!licence.isActive">無効</div>
                  <div v-else-if="licence.isUpdate">更新中</div>
                  <div v-else>有効</div>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>許可証番号</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ licence.licenceNo }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>許可証期限</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ licence.licenceExpiredDate }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>優良認定</label>
                </div>
                <div class="c-inputWrap__items">
                  <div v-if="licence.isExcellent" class="c-excellent"/>
                  <div v-else>-</div>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>許可証PDF</label>
                </div>
                <div class="c-inputWrap__items">
                  <a v-if="licence.licenceFileName" class="c-text text-link c-entrustText__text"
                     @click="downloadLicenceFile(licence.disposalLicenceId, licence.licenceFileName, true)">
                    {{ licence.licenceFileName }}
                  </a>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>対象廃棄物種類</label>
                </div>
                <div class="c-inputWrap__items">
                  <ul
                    class="l-flex c-inputWrap__items__cols"
                    v-if="licence.targetJwnetItemList.length"
                  >
                    <li>
                      {{ licence.targetJwnetItemList.map(jwnetItem => jwnetItem.name).join("、") }}
                    </li>
                  </ul>
                  <div v-else>-</div>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>処分方法</label>
                </div>
                <div class="c-inputWrap__items">
                  <div
                    class="l-item"
                    v-for="(
                      disposalWay, disposalWayIndex
                    ) in licence.disposalWayList"
                    :key="'disposalWay-' + disposalWayIndex"
                  >
                    <p>{{ disposalWay.disposalWayType.name }}</p>
                    <p>{{ disposalWay.disposalDetailWay }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.l-contents -->

        <!-- 処分場情報 ================================================== -->
        <div class="c-lead l-area">処分場情報</div>
        <template
          v-for="(disposalSite, disposalSiteIndex) in disposer.disposalSiteList"
        >
          <div :key="'disposalSite-' + disposalSiteIndex">
            <div class="c-lead l-item">
              {{ disposalSite.disposalSiteBaseInfo.name }}
            </div>
            <div class="l-contents l-box">
              <div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>住所</label>
                  </div>
                  <div class="c-inputWrap__items">
                    〒{{ disposalSite.disposalSiteBaseInfo.zipCode }}<br />
                    {{ disposalSite.disposalSiteBaseInfo.prefecturesName }}
                    {{ disposalSite.disposalSiteBaseInfo.address }}
                    {{ disposalSite.disposalSiteBaseInfo.buildingName }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>電話番号</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ disposalSite.disposalSiteBaseInfo.tel | blankText }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>FAX番号</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ disposalSite.disposalSiteBaseInfo.fax | blankText }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>JWNETID(加入者番号)</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ disposalSite.jwnetContractNo | blankText }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>EDI利用確認キー</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ disposalSite.ediKey | blankText }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>事業場コード</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ disposalSite.disposalStoreCode | blankText }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>公開確認番号</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ disposalSite.publicConfirmationNo | blankText }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>許可証番号</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <ul
                      class="l-flex c-inputWrap__items__cols"
                      v-if="disposalSite.disposalSiteLicenceList.length"
                    >
                      <li
                        v-for="siteLicence in disposalSite.disposalSiteLicenceList"
                        :key="'siteLicence-' + siteLicence.id"
                      >
                        {{ siteLicence.disposalLicenceNo }}
                      </li>
                    </ul>
                    <div v-else>-</div>
                  </div>
                </div>

                <div class="c-inputWrap" v-if="disposalSite.finalDisposalSiteList.length">
                  <div class="c-inputWrap__label">
                    <label>最終処分場情報</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div
                        class="c-accordion"
                        v-for="final in disposalSite.finalDisposalSiteList"
                        :key="'final-' + final.finalDisposalSiteId"
                    >
                      <input
                          :id="'final-acc-' + final.finalDisposalSiteId"
                          class="c-accordion__cb"
                          type="checkbox"
                      />
                      <div class="c-accordion__head">
                        <label
                            class="c-accordion__head__lbl"
                            :for="'final-acc-' + final.finalDisposalSiteId"
                        ></label>
                        <div class="c-accordion__head__text">
                          {{ final.finalDisposalSiteName }}
                        </div>
                      </div>
                      <div class="c-accordion__body">
                        <div class="c-inputWrap">
                          <div class="c-inputWrap__label">
                            <label>住所</label>
                          </div>
                          <div class="c-inputWrap__items">
                            〒{{ final.addressInfo.zipCode }}
                            {{ final.addressInfo.prefecturesName }}
                            {{ final.addressInfo.address }}
                            {{ final.addressInfo.buildingName }}
                          </div>
                        </div>
                        <div class="c-inputWrap">
                          <div class="c-inputWrap__label">
                            <label>電話番号</label>
                          </div>
                          <div class="c-inputWrap__items">
                            {{ final.tel | blankText }}
                          </div>
                        </div>
                        <div class="c-inputWrap">
                          <div class="c-inputWrap__label">
                            <label>事業場コード</label>
                          </div>
                          <div class="c-inputWrap__items">
                            {{ final.finalDisposalStoreCode | blankText }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-if="disposer.isCbaContract">
          <!--契約情報-->
          <div class="l-contents l-box">
            <div class="l-box title-wrap">
              <h3 class="c-lead icon">契約情報</h3>
            </div>
            <div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>代理登録</label>
                </div>
                <div v-if="disposer.isDisposalProxy">
                  <div class="c-inputWrap__items">有</div>
                </div>
                <div v-else class="c-inputWrap__items">無</div>
              </div>
            </div>
          </div>
          <!--契約情報ここまで-->

          <!--管理者アカウント情報-->
          <div class="l-contents l-box">
            <div class="l-box title-wrap">
              <h3 class="c-lead icon">管理者アカウント情報</h3>
            </div>
            <div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>氏名</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ disposer.accountInfo.name }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>メールアドレス</label>
                </div>
                <div class="c-inputWrap__items word-break">
                  {{ disposer.accountInfo.mail }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>電話番号</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ disposer.accountInfo.tel }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>電話番号(携帯)</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ disposer.accountInfo.mobilePhone }}
                </div>
              </div>
            </div>
          </div>
          <!--管理者アカウント情報ここまで-->
        </template>
      </div>

      <FloatButtons>
        <div class="left">
          <router-link to="/operate" class="c-btn secondary small back">一覧へ戻る</router-link>
        </div>
        <div class="center">
          <div class="c-btn secondary small" @click="isActiveHandleModalShow = true">{{ isActiveRegister ? "無効化" : "有効化" }}</div>
          <router-link :to="`/disposer/step1/edit/${disposalCompanyId}`" class="c-btn secondary small">編集</router-link>
          <template v-if="disposer.isCbaContract">
            <router-link :to="`/disposer/cbacontract/edit/${$route.params.id}`" class="c-btn secondary small">CBA加入情報編集</router-link>
            <div class="c-btn secondary small" @click="isDisplayQuitModal = true">CBA契約終了</div>
          </template>
          <template v-else>
            <router-link :to="`/disposer/cbacontract/input/${$route.params.id}`"
                          class="c-btn secondary small">
              CBA加入登録
            </router-link>
          </template>
        </div>
        <div class="right">
          <router-link to="/account" class="c-btn secondary small next">アカウント一覧へ</router-link>
        </div>
      </FloatButtons>
      <!-- /.l-container -->
    </main>

    <!--準備完了メール送信確認モーダル-->
    <div class="c-modal" v-if="isDisplayMailModal">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          準備完了メールを送信します。<br />よろしいですか？
        </div>
        <div class="c-modal__foot">
          <div
              class="c-btn secondary small"
              @click="isDisplayMailModal = false"
          >
            キャンセル
          </div>
          <div :class="{ isProcessing }"  class="c-btn primary small" @click="confirmHandler()">
            送信
          </div>
        </div>
      </div>
    </div>
    <!--準備完了メール送信確認モーダルここまで-->

    <!-- 有効・無効化確認モーダル -->
    <div class="c-modal" v-show="isActiveHandleModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          処分事業者を{{ isActiveRegister ? "無効化" : "有効化" }}します。<br />
          よろしいですか？
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn secondary small"
            @click="isActiveHandleModalShow = false"
          >
            戻る
          </div>
          <div class="c-btn primary small" @click="registerActiveHandler">
            OK
          </div>
        </div>
      </div>
    </div>

    <!--CBA契約終了確認モーダル-->
    <div class="c-modal" v-if="isDisplayQuitModal">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          CBA契約を終了します。<br />
          よろしいですか？
        </div>
        <div class="c-modal__foot">
          <div
              class="c-btn secondary small"
              @click="isDisplayQuitModal = false"
          >
            戻る
          </div>
          <div class="c-btn primary small" @click="quitHandler()">OK</div>
        </div>
      </div>
    </div>
    <!--CBA契約終了確認モーダルここまで-->

    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import callApi from "./mixins/callApi";
import { API_URL } from "@/const";
import { saveAs } from "file-saver";
import formValue from "./mixins/formValue";
import ToastMessage from "@/components/parts/ToastMessage";
import {LEGAL_PERSONALITY_TYPE} from "@/const";
import FloatButtons from "@/components/layout/FloatButtons";
import validation from "@/mixin/validation";
import formValidation from "@/pages/disposer/detail/mixins/formValidation";
import InputDate from "@/components/parts/input/InputDate";
export default {
  name: "contract-detail",
  components: {FloatButtons, ToastMessage, InputDate},
  mixins: [callApi, formValue, validation, formValidation],
  data() {
    return {
      isActiveHandleModalShow: false,
      disposalCompanyId: null,
      startDate: "",
      isAccountModalShow: false,
      toastMsg: "",
      companyFullName: "",
      isDisplayQuitModal: false,
      isDisplayMailModal: false,
      isProcessing: false,
    };
  },

  computed: {
    isActiveRegister() {
      if (this.disposer.disposerBaseInfo) {
        return this.disposer.disposerBaseInfo.isActive;
      } else {
        return false;
      }
    },
  },

  methods: {
    // 処分事業者情報取得API   DISPOSER.INFO + {this.disposalCompanyId}
    getDisposerInfoApi() {
      return axios
          .get(API_URL.DISPOSER.INFO + this.disposalCompanyId)
          .then((res) => {
            this.disposer = res.data;
            this.form.disposalCompanyId = res.data.disposerBaseInfo.id;
            this.form.managementMemberId = res.data.accountInfo ? res.data.accountInfo.memberId : null;
            this.form.accountStartDate = res.data.accountStartDate;
            this.companyFullName = this.setCompanyFullName(res.data.disposerBaseInfo)
          })
          .catch((err) => {
            console.log(err);
          });
    },

    // 有効無効モーダル登録時
    registerActiveHandler() {
      const isActive = !this.isActiveRegister;
      axios
        .patch(`${API_URL.DISPOSER.INFO}${this.disposalCompanyId}`, {
          isActive: isActive,
        })
        .then(() => {
          this.isActiveHandleModalShow = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.getDisposerInfoApi();
          this.toastMsg = `処分事業者を${ isActive ? "有効化" : "無効化"}しました。`;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //許可証のファイルをダウンロード
    downloadLicenceFile(disposalLicenceId, licenceFileName, isCommonLicence) {
      const params = new URLSearchParams();
      params.append("isCommonLicence", isCommonLicence)
      axios
          .get(API_URL.DISPOSER.LICENCE_DOWNLOAD + disposalLicenceId + '?' + params.toString(), {
            responseType: "blob",
          })
          .then((res) => {
            const blob = new Blob([res.data], {
              type: res.data.type
            });
            saveAs(blob, licenceFileName);
          });
    },
    setCompanyFullName(disposerBaseInfo) {
      var legalPersonalityTypeName = disposerBaseInfo.legalPersonalityType.name;
      var companyName = disposerBaseInfo.name;
      const regExp = /\(([^)]+)\)/;
      const matches = regExp.exec(legalPersonalityTypeName);
      if (matches) {
        var location = matches[1];
        if (location === LEGAL_PERSONALITY_TYPE.LOCATION.BEFORE) {
          return legalPersonalityTypeName.replace(matches[0], "") + companyName ;
        } else if (location === LEGAL_PERSONALITY_TYPE.LOCATION.AFTER) {
          return companyName + legalPersonalityTypeName.replace(matches[0], "");
        }
        return companyName;
      } else return companyName;
    },

    // CBA契約終了確認モーダル OK押下
    quitHandler() {
      this.$data.isDisplayQuitModal = false;
      axios
        .put(API_URL.DISPOSER.CBACONTRACT_EDIT + this.$route.params.id, this.formQuit)
        .then((res) => {
          // リロード
          this.$router.go({
            path: this.$router.currentRoute.path,
            force: true,
          });
          console.log(res);
        })
        .catch((err) => {
          this.errMsgs.push(err.message);
        });
    },
    // 準備完了メール送信押下時
    formHandler() {
      if (!this.runValidate()) return;
      this.$data.isDisplayMailModal = true;
    },

    confirmHandler() {
      this.isProcessing = true;
      this.$data.isDisplayMailModal = false;
      const form = {
        disposalCompanyId: this.form.disposalCompanyId,
        accountStartDate: this.startDate,
        managementMemberId: this.form.managementMemberId,
      };
      axios
          .put(API_URL.DISPOSER.SENDMAIL, form)
          .then((res) => {
            this.isProcessing = false;
            // リロード
            this.$router.go({
              path: this.$router.currentRoute.path,
              force: true,
            });
            console.log(res);
          })
          .catch((err) => {
            this.isProcessing = false;
            this.errMsgs.push(err.message);
          });
    },
  },

  mounted() {
    this.disposalCompanyId = this.$route.params.id;
    this.getDisposerInfoApi(this.disposalCompanyId);
  },

};
</script>
