export default {
    data() {
      return {
        // 準備完了メール送信のフォーム
        form: {
          accountStartDate: "",
          disposalCompanyId: "",
          managementMemberId: "",
        },
        // CBA加入終了のフォーム
        formQuit: {
          isCBAContract: false,
          contractStatusTypeId: 90,
          isDisposalProxy: false,
        },
      };
    },
  };
  